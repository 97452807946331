// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import KnowledgeArticle from 'components/Contentful/KnowledgeArticle'
import SideNavigation from 'components/SideNavigation'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Wärmepumpen-Förderbeitrag von myclimate"
        meta={[
          { name: 'description', content: 'Alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen und rund CHF 3.800 kassieren ✔️ myclimate Förderprogramm ✔️ Hier informieren ✔️' },
          { name: 'keywords', content: 'Förderbeitrag Wärmepumpe' },
        ]}
      >
        
      </Helmet>
      
      <div className='section__nav section--padded'><SideNavigation location={props.location} links={[
    {
        "type": "link",
        "id": "6imdAPCpzzJin32ITPF7Tv",
        "updatedAt": "2020-03-18T15:08:38.076Z",
        "label": "Schweizweite Förderung",
        "page": {
            "slug": "foerdergelder/schweizweite-foerderung"
        }
    },
    {
        "type": "link",
        "id": "4OW4jSV0Vk2H7QMmCcj8gc",
        "updatedAt": "2021-09-03T09:57:55.973Z",
        "label": "Das Gebäudeprogramm",
        "page": {
            "slug": "foerdergelder/das-gebaeudeprogramm"
        }
    },
    {
        "type": "link",
        "id": "cIQKSgk11rayF9abVfi4k",
        "updatedAt": "2020-03-27T08:01:44.514Z",
        "label": "Häufig gestellte Fragen - FAQ",
        "page": {
            "slug": "foerdergelder/haeufig-gestellte-fragen-zum-thema-foerdergelder-waermepumpe"
        }
    },
    {
        "type": "link",
        "id": "2MTu40Ef1Gc5Sl6BRdqp5e",
        "updatedAt": "2020-03-13T15:09:44.207Z",
        "label": "Fördergelder Kanton Aargau",
        "page": {
            "slug": "foerdergelder/kanton-aargau"
        }
    },
    {
        "type": "link",
        "id": "6jyOwojHZQnjIMizqwQnXQ",
        "updatedAt": "2020-03-23T09:16:33.860Z",
        "label": "Fördergelder Kanton Basel-Landschaft",
        "page": {
            "slug": "foerdergelder/kanton-basel-landschaft"
        }
    },
    {
        "type": "link",
        "id": "539aNbJu1biigQT5Unbhd2",
        "updatedAt": "2020-03-23T10:22:43.087Z",
        "label": "Fördergelder Kanton Basel-Stadt",
        "page": {
            "slug": "foerdergelder/kanton-basel-stadt"
        }
    },
    {
        "type": "link",
        "id": "2jA1lO1AcRz7oZ2sSFx9vg",
        "updatedAt": "2020-03-23T10:27:44.702Z",
        "label": "Fördergelder Kanton Bern",
        "page": {
            "slug": "foerdergelder/kanton-bern"
        }
    },
    {
        "type": "link",
        "id": "223GuzpRXRVSaAYk6BFtSq",
        "updatedAt": "2020-03-23T11:00:32.709Z",
        "label": "Fördergelder Kanton Luzern",
        "page": {
            "slug": "foerdergelder/kanton-luzern"
        }
    },
    {
        "type": "link",
        "id": "1t30LGgkuILdIGApksWHvv",
        "updatedAt": "2023-09-28T15:35:50.064Z",
        "label": "Fördergelder Kanton Schaffhausen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-schaffhausen"
        }
    },
    {
        "type": "link",
        "id": "11DxxtCQGVZdWGHeKaLoUq",
        "updatedAt": "2020-03-24T09:20:23.945Z",
        "label": "Fördergelder Kanton Solothurn",
        "page": {
            "slug": "foerdergelder/kanton-solothurn"
        }
    },
    {
        "type": "link",
        "id": "3mbZseYEYjKC7VQPe24HQS",
        "updatedAt": "2023-04-20T15:20:55.004Z",
        "label": "Fördergelder Kanton St. Gallen",
        "page": {
            "slug": "foerdergelder/foerdergelder-kanton-st-gallen"
        }
    },
    {
        "type": "link",
        "id": "5RPB8qgJ9nc7aoIwoFxmul",
        "updatedAt": "2023-04-20T15:21:32.792Z",
        "label": "Fördergelder Kanton Thurgau",
        "page": {
            "slug": "foerdergelder/kanton-thurgau"
        }
    },
    {
        "type": "link",
        "id": "4mUIIT6zOW58vyTDWkKUGx",
        "updatedAt": "2020-03-24T10:00:31.055Z",
        "label": "Fördergelder Kanton Zug",
        "page": {
            "slug": "foerdergelder/kanton-zug"
        }
    },
    {
        "type": "link",
        "id": "59vmXjjI3AnPXmVsC4kKBz",
        "updatedAt": "2020-03-24T10:03:26.817Z",
        "label": "Fördergelder Kanton Zürich",
        "page": {
            "slug": "foerdergelder/kanton-zuerich"
        }
    }
]} /><div
          className='bg__flex--white'
          key='1zgjiLjz3qEPMjsgOk2B60-3MG0ZEvFi5F8v7Aep05uds'
          id=''
        >
          <KnowledgeArticle
            {...{
    "type": "knowledgeArticle",
    "id": "3MG0ZEvFi5F8v7Aep05uds",
    "updatedAt": "2023-04-20T16:10:27.182Z",
    "title": "Schweizweite Förderung myclimate",
    "keyword": "Schweizweite Förderung",
    "articleType": "Infoartikel",
    "description": "Die Stiftung myclimate unterstützt Sie mit rund CHF 3'800, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen.",
    "content": [
        {
            "type": "infoarticle",
            "id": "RDHM7UzeP0UbcPZO7PemF",
            "updatedAt": "2021-11-11T08:37:31.475Z",
            "title": "myclimate",
            "text": "<p>Unabhängig von den kantonalen Förderstellen, unterstützt die Stiftung myclimate Bauherrschaften, wenn Sie Ihre alte Öl- oder Erdgasheizung durch eine Wärmepumpe ersetzen. <strong>myclimate erhöht ihre Förderbeiträge auf 0.18 CHF/kWh</strong> basierend auf dem jährlichen Energieverbrauch und beträgt <strong>mindestens CHF 2'000</strong>. Der Förderbeitrag erhöht sich somit auf <strong>rund CHF 3'800</strong> für Wärmepumpen bis 15 kW.</p>\n<p>Es werden nur Wärmepumpen gefördert, die zum Zeitpunkt der Anmeldung keine anderen Fördergelder von Kanton oder Gemeinde beziehen können.</p>\n<h3>Förderbeitrag</h3>\n<table>\n<thead>\n<tr>\n<th>Wärmepumpe</th>\n<th>Beitrag</th>\n</tr>\n</thead>\n<tbody>\n<tr>\n<td>Luft/Wasser</td>\n<td>Ø CHF 3'800</td>\n</tr>\n<tr>\n<td>Sole/Wasser</td>\n<td>Ø CHF 3'800</td>\n</tr>\n</tbody>\n</table>\n<h3>Förderstelle</h3>\n<p>myclimate<br>\nPfingstweidstrasse 10<br>\n8005 Zürich<br>\nTel: 044 500 43 50<br>\n<a href=\"https://www.myclimate.org/de/informieren/klimaschutzprojekte/detail-klimaschutzprojekte/schweiz-energieeffizienz-7816/\">Website<br>\n</a></p>\n",
            "gallery": [
                {
                    "type": "youTubeLink",
                    "id": "501asswDZBxK8SoslcT1ws",
                    "updatedAt": "2020-03-20T10:31:06.030Z",
                    "title": "myclimate - Klimaschutz lokal und global",
                    "link": "https://youtu.be/8YGN23ULs_o",
                    "description": "<p>Die Stiftung myclimate stellt sich vor.</p>\n",
                    "videoId": "8YGN23ULs_o",
                    "start": null
                }
            ]
        }
    ],
    "topic": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "previewImage": {
        "description": "Logo farbig myclimate shape our future",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/3QFNO7y5VfxkijsSNc0nlP/0a08a3360312ba1ed64815967168e16e/1200px-Myclimate_201x_logo.svg.png"
    }
}} location={props.location}
          />
        </div></div>
    </Layout>
  )
}

export default Page
